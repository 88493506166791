<template>
  <div>
    <b-form-group label-for="user-term">
      <b-form-input
        id="user-term"
        v-model="options.term"
        placeholder="Recherche par nom"
        autofocus
        @change="updateFilters()"
      />
    </b-form-group>
    <!-- <pre>{{ options }}</pre> -->
  </div>
</template>
<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue'

export default {
  name: 'StoriesFilters',
  components: {
    BFormGroup,
    BFormInput,
  },
  props: {
    filters: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      options: {
        term: null,
      },
    }
  },
  created() {
    this.options = Object.assign(this.options, this.filters)
  },
  methods: {
    updateFilters() {
      this.$router
        .push({
          path: this.$router.history.current.path,
          query: Object.assign(this.options, { page: 1 }),
        })
        .catch((error) => {
          if (error.name !== 'NavigationDuplicated') {
            // throw error
            console.log(error)
          }
        })
    },
  },
}
</script>
