<template>
  <b-card title="">
    <div class="card-title">
      <div class="row">
        <div class="col-md-6">
          <h4 class="mb-0">{{ title }}</h4>
        </div>
        <div class="col-md-6 text-right">
          <button
            class="btn btn-info btn-icon mr-1"
            @click.prevent="displayForm = !displayForm"
          >
            <feather-icon icon="PlusIcon" />
          </button>
          <button
            class="btn btn-info btn-icon"
            @click.prevent="displayFilters = !displayFilters"
          >
            <feather-icon icon="FilterIcon" />
          </button>
        </div>
      </div>
    </div>
    <div class="m-lg-5 mb-5">
      <stories-filters
        v-if="displayFilters"
        :filters.sync="filters"
        @updated="setPage(1)"
      />
    </div>
    <div v-if="displayForm == true" class="m-lg-5 mb-5">
      <h3>Ajouter un post</h3>
      <story-form
        :creation="true"
        :display.sync="displayForm"
        @created="onCreate"
      />
    </div>
    <table class="table table-hover">
      <tbody>
        <template v-for="story in stories">
          <tr
            :key="story.id"
            @click.prevent="$router.push(`/stories/${story.id}`)"
          >
            <td>
              <h5 v-if="story.rich_title" class="m-b-0 m-t-0">
                <span v-if="story.scheme === 'STORY'" class="mr-1">
                  <fa :icon="['fas', 'newspaper']" />++
                </span>
                <span v-if="story.scheme === 'VIDEO'" class="mr-1">
                  <fa :icon="['fas', 'tv']" />
                </span>
                <span v-html="story.rich_title" />
              </h5>
              <h5 v-else>
                {{ story.title || 'Aucun titre' }}
              </h5>
              <div>
                <span
                  :class="{
                    'badge-success': story.status == 'PUBLISHED',
                    'badge-danger': story.status == 'DISABLED',
                  }"
                  class="badge m-l-5 m-r-5"
                >
                  {{ $t(`STORY.${story.status}`) }}
                </span>
                <span
                  v-if="story.scope === 'PRIVATE'"
                  class="badge badge-danger m-l-5 m-r-5"
                >
                  <font-awesome-icon icon="eye-slash" />
                </span>
                <span v-if="story.authors.length > 0">
                  par
                  <span v-for="author in story.authors" :key="author.id">
                    {{ author.screenname }}
                  </span>
                </span>
                | créé
                {{ $dayjs(story.created_at).format('DD/MM/YYYY') }} - publié le
                {{ $dayjs(story.published_at).format('DD/MM/YYYY à HH:mm') }}
                <span
                  v-if="story.primary_category && story.primary_category.label"
                >
                  -
                  <span>
                    <font-awesome-icon icon="folder-open" />
                    {{ story.primary_category.label }}
                  </span>
                </span>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <pagination-custom
      :page="filters.page"
      :total="pagination.total"
      :per-page="filters.per_page"
      class="text-center mt-3"
      @change="setPage"
    />
    <!-- <pre>{{ stories }}</pre> -->
    <!-- <pre>{{ pagination }}</pre> -->
    <!-- <pre>{{ filters }}</pre> -->
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import StoryForm from '@/components/StoryForm.vue'
import StoriesFilters from './StoriesFilters.vue'

const fields =
  'results{' +
  'id,slug,title,rich_title,status,scope,thumb_story_list_url,scheme,created_at,' +
  'published_at,primary_category{label,scheme}' +
  'authors{id,screenname}' +
  '}' +
  ',meta'

export default {
  components: {
    BCard,
    StoriesFilters,
    StoryForm,
  },
  data() {
    return {
      displayFilters: false,
      displayForm: false,
      stories: [],
      pagination: { total: 0 },
      filters: {
        page: 1,
        per_page: 25,
        term: null,
      },
    }
  },
  computed: {
    title() {
      if (this.pagination.total) {
        return `${this.pagination.total} éléments - page ${this.pagination.page} / ${this.pagination.pages}`
      }
      return '…'
    },
  },
  watch: {
    $route() {
      this.init()
    },
  },
  async created() {
    this.filters = Object.assign(this.filters, this.$route.query)
    this.init()
  },
  methods: {
    async init() {
      const resp = await this.$http.get('/stories/', {
        params: this.filters,
        headers: { 'X-Fields': fields },
      })
      this.stories = resp.data.results
      this.pagination = resp.data.meta
      this.$forceUpdate()
      this.scrollToTop()
    },
    setPage(page) {
      if (page) {
        this.filters.page = page
      }
      this.$router
        .push({
          path: this.$router.history.current.path,
          query: this.filters,
        })
        .catch((error) => {
          if (error.name !== 'NavigationDuplicated') {
            // throw error
            console.log(error)
          }
        })
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    onCreate(story) {
      this.displayForm = false
      this.$router.push(`/stories/${story.id}/`)
    },
  },
}
</script>

<style></style>
