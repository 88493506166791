<template>
  <div>
    <validation-observer ref="formValidation">
      <b-form @submit.prevent>
        <template v-if="creation == true || storyId">
          <b-form-group label-for="input-title">
            <validation-provider
              #default="{ errors }"
              name="Intitulé"
              rules="required"
            >
              <b-form-input
                id="input-title"
                v-model="form.title"
                type="text"
                :state="errors.length > 0 ? false : null"
                placeholder="Titre du post"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </template>
        <template v-if="storyId">
          <b-form-group label-for="input-scheme">
            <b-form-select
              v-model="primary_category_id"
              @change="selectPrimaryCategory"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>
                  -- Rubriques --
                </b-form-select-option>
              </template>
              <b-form-select-option
                v-for="option in computedCategories"
                :key="option.id"
                :value="option.id"
              >
                {{ option.label }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
          <b-form-group>
            <b-form-radio-group
              v-model="form.status"
              :options="[
                { text: 'En cours d’édition', value: 'DISABLED' },
                { text: 'Publié', value: 'PUBLISHED' },
              ]"
              name="status"
            />
          </b-form-group>
          <b-form-group>
            <b-form-radio-group
              v-model="form.scope"
              :options="[
                { text: 'Caché', value: 'PRIVATE' },
                { text: 'Visible par tout', value: 'PUBLIC' },
              ]"
              name="scope"
            />
          </b-form-group>
          <b-form-group label-for="input-headline">
            <b-form-textarea
              id="input-headline"
              v-model="form.headline"
              rows="4"
              placeholder="Chapô"
            />
          </b-form-group>
          <b-form-group label-for="input-cover_image_credits">
            <b-form-textarea
              id="input-cover_image_credits"
              v-model="form.cover_image_credits"
              rows="2"
              placeholder="Crédits photos"
            />
          </b-form-group>
          <b-form-group label-for="input-seo_title">
            <b-form-input
              id="input-seo_title"
              v-model="form.seo_title"
              type="text"
              placeholder="Titre SEO"
            />
          </b-form-group>
          <b-form-group label-for="input-seo_description">
            <b-form-textarea
              id="input-seo_description"
              v-model="form.seo_description"
              rows="2"
              placeholder="Description SEO"
            />
          </b-form-group>
        </template>
        <hr />
        <div class="row mt-3">
          <div class="col-md-6">
            <b-button
              variant="primary"
              class="mr-1"
              @click.prevent="validationForm"
            >
              Valider
            </b-button>
            <b-button
              variant="light"
              class="mr-1"
              @click.prevent="$emit('update:display', false)"
            >
              Annuler
            </b-button>
          </div>
          <div class="col-md-6 text-right">
            <b-button v-if="storyId" v-b-modal.modal-delete variant="danger">
              Supprimer
            </b-button>
          </div>
        </div>
      </b-form>
    </validation-observer>
    <b-modal
      id="modal-delete"
      ref="delete-recipe"
      centered
      hide-header-close
      title="Suprimmer le post"
    >
      <p class="my-2">Confirmez-vous la suppression de cet élément ?</p>
      <template #modal-footer="{ ok, cancel, hide }">
        <b-button size="sm" variant="danger" @click="deleteStory()">
          Confirmer
        </b-button>
        <b-button size="sm" variant="outline-secondary" @click="hide('forget')">
          Annuler
        </b-button>
      </template>
    </b-modal>
    <!-- <pre>{{ form }} </pre> -->
    <!-- <pre>{{ categories }} </pre> -->
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, double } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
  BFormRadioGroup,
  BButton,
  BModal,
} from 'bootstrap-vue'

export default {
  name: 'StoryForm',
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    BFormRadioGroup,
    BButton,
    BModal,
    ValidationProvider,
    ValidationObserver,
  },
  inheritAttrs: false,
  props: {
    creation: {
      default: false,
      type: Boolean,
    },
    storyId: {
      default: null,
      type: String,
    },
    display: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      required,
      double,
      locale: 'fr',
      categories: [],
      primary_category_id: null,
      form: {
        status: null,
        title: null,
        scope: null,
        headline: null,
        primary_category_id: null,
        cover_image_credits: null,
        seo_title: null,
        seo_description: null,
      },
    }
  },
  computed: {
    computedCategories() {
      return JSON.parse(JSON.stringify(this.categories || []))
    },
  },
  watch: {
    storyId() {
      this.init()
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      if (this.storyId) {
        const respTags = await this.$http.get(`/tags/`, {
          params: {
            schemes: 'CATEGORY',
            per_page: 100,
          },
          headers: {
            'X-Fields': 'results{id,scheme,label}',
          },
        })
        this.categories = respTags.data.results

        const resp = await this.$http.get(`/stories/${this.storyId}/`, {
          headers: {
            'X-Fields': `${Object.keys(this.form).join(
              ','
            )},primary_category{id,label}`,
          },
        })
        this.form = resp.data
        if (this.form.primary_category) {
          this.primary_category_id = this.form.primary_category.id
          this.form.primary_category_id = this.form.primary_category.id
        }
      }
    },
    validationForm() {
      this.$refs.formValidation.validate().then((success) => {
        if (success === true && this.creation === true) {
          this.create()
        } else if (success === true && this.storyId) {
          this.update()
        }
      })
    },
    async create() {
      try {
        const respCsrf = await this.$http.get('/auth/csrf/')
        const resp = await this.$http.post(
          '/stories/',
          { status: 'ENABLED', ...this.form },
          {
            headers: {
              'X-Fields': 'id',
              'X-CSRF-TOKEN': respCsrf.data.csrf,
            },
          }
        )
        this.$emit('created', resp.data)
      } catch (e) {
        console.log(e)
      }
    },
    async update() {
      try {
        const respCsrf = await this.$http.get('/auth/csrf/')
        const resp = await this.$http.put(
          `/stories/${this.storyId}/`,
          this.form,
          {
            headers: {
              'X-Fields': 'id',
              'X-UpdateKeys': Object.keys(this.form).join(','),
              'X-CSRF-TOKEN': respCsrf.data.csrf,
            },
          }
        )
        this.$emit('updated', resp.data)
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Enregistrement effectué',
              icon: 'SaveIcon',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
          }
        )
      } catch (e) {
        console.log(e)
      }
    },
    async duplicateStory() {
      const respCsrf = await this.$http.get('/auth/csrf/')
      const resp = await this.$http.post(
        `/stories/${this.storyId}/duplicate/`,
        {},
        {
          headers: {
            'X-Fields': 'id',
            'X-CSRF-TOKEN': respCsrf.data.csrf,
          },
        }
      )
      this.$emit('duplicated', resp.data)
    },
    async deleteStory() {
      const respCsrf = await this.$http.get('/auth/csrf/')
      const resp = await this.$http.delete(`/stories/${this.storyId}/`, {
        headers: {
          'X-Fields': 'id',
          'X-UpdateKeys': 'id',
          'X-CSRF-TOKEN': respCsrf.data.csrf,
        },
      })
      this.$emit('deleted', resp.data)
      this.$refs['delete-recipe'].hide()
    },
    selectPrimaryCategory(value) {
      this.form = Object.assign(this.form, { primary_category_id: value })
      this.$forceUpdate()
    },
  },
}
</script>
